global.$ = require("jquery");
global.jQuery = global.$;
import 'jquery';
import 'bootstrap';
import 'slick-carousel';
import JsCookie from "js-cookie";
import AOS from "aos";

$(function () {
    AOS.init();
    window.addEventListener("load", AOS.refresh);

    agePopup();

    const $header = $('.js-header');
    const $navTrigger = $('.js-nav-trigger');

    $navTrigger.on('click', function (e) {
        e.preventDefault();
        $header.toggleClass('is-active');
    });

    $('body').on('hidden.bs.modal', '.modal', function(){
        $('.modal').remove();
    })
})

function agePopup(){
    let showPopup = JsCookie.get('HasAcceptedPopup');

    if(showPopup){
        return;
    }

    $('body').append(`
        <div class="modal fade age-modal" id="age-modal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    
                </div>
            </div>
        </div>`);

    $('#age-modal .modal-content').load('ajax/modals/age-modal.html', function(){
        $('#age-modal').modal({backdrop: 'static', keyboard: false}).modal('show');

        let $this = $(this);

        $this.find('#is-over-21').on('change', function () {
            let isChecked = $(this).is(':checked');

            if(isChecked){
                $this.find('#accept-age').prop('disabled', false);
            }
            else{
                $this.find('#accept-age').prop('disabled', true);
            }
        })

        $this.find('#accept-age').on('click', function () {
            JsCookie.set('HasAcceptedPopup', 1);
            $('#age-modal').modal('hide');
        })
    });
}